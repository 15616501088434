import React from 'react'
import checkPrice from './checkPrice'

const checkTotalPrice = (arr) => {
    let totalPrice = 0;
    // arr.map((num) => {
    //     totalPrice = totalPrice + parseInt(checkPrice(num.toString()))
       
    // })

    

    totalPrice = 1*arr.lenght;

    return arr.lenght;
}

export default checkTotalPrice
